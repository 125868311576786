@import ".././mixins";

.interventions-filters {
    border-radius: .4em .4em 0 0 !important;
    border-bottom: 0 !important;
    @include flexbox(flex-start !important, center, row !important, nowrap);
    padding-left: 2em !important;
    *:not(:first-child), *:not(:last-child) {
        margin-right: 2em; }
    &__title {
        font-weight: 500;
        color: rgba($main-text-color, .64);
        font-size: .95em;
        letter-spacing: .045em;
        margin: 0 2em 0 1.5em; }
    select {
        max-width: 22em; } }
.action-icon-container {
    @include flexbox(center, center, row, nowrap);
    .action-icon {
        cursor: pointer;
        @include flexbox(center, center, row, nowrap);
        padding: .5em;
        border-radius: 50%;
        width: 1.4em;
        background-color: transparent;
        transition: background-color .2s ease-out;
        &:hover {
            background-color: rgba($main-text-color, .14); } } }

.interventions-table {
    border-radius: 0 0 .4em .4em !important;
    position: relative;
    width: 100%;
    margin-bottom: 0; }

