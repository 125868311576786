@import "../mixins";

.meteo-panel {
    display: grid;
    grid-template-columns: repeat(auto-fill, 16em);
    grid-gap: 2em;
    .meteo-card {
        justify-content: space-around;
        flex-direction: row !important;
        .left {
            width: 4em;
            height: 5em;
            @include flexbox(center, center, column, nowrap); }
        .right {
            width: 8em;
            @include flexbox(center, center, column, nowrap);
            .temp {
                font-size: 2.4em;
                font-weight: 200;
                margin-bottom: .2em; }
            .min-max-container {
                @include flexbox(space-around, center, row, nowrap);
                width: 8em;
                @include breakpoint("<phone") {
                    width: 5em; }
                .min, .max {
                    @include flexbox(flex-start, center, row, nowrap);
                    position: relative;
                    font-size: .8em; }
                .min:before, .max:before {
                    content: '';
                    @include position(absolute, unset, unset, unset, -10px);
                    @include square(6px);
                    border-radius: 2em;
                    @include breakpoint("<phone") {
                        left: -7px; }
                    @include breakpoint("<small-phone") {
                        left: -5px;
                        @include square(2px); } }
                .min:before {
                    background-color: $main-accent-color; }
                .max:before {
                    background-color: $red; } }
            #wind-value:after,
            #percentage-value:after {
                font-size: .5em;
                margin-left: .3em; }
            #wind-value:after {
                content: 'm/s'; }
            #percentage-value:after {
                content: '%'; } } } }
