@import "./mixins";

.probably-drizzle {
    background-color: #009f9f; }

.drizzle {
    background-color: #008c4b; }

.light-rain {
    background-color: #00d319; }

.light-rain-2 {
    background-color: #21fd22; }

.rain-box {
    background-color: #fffd1b; }

.rain-box-2 {
    background-color: #ffd400; }

.heavy-rain {
    background-color: #ffab00; }

.heavy-rain-2 {
    background-color: #ff6e00; }

.storm {
    background-color: #f01002; }

.storm-2 {
    background-color: #d00523; }

.hail-box {
    background-color: #e400b1; }

.hail-box-2 {
    background-color: #ff00ff; }

.visible-list {
  visibility: visible;
  display: flex !important; }

.App {
    .main-card {
        @include flexbox(flex-start, flex-start, row, nowrap);
        padding: 0;
        margin-top: 2em;
        height: calc(100vh - 17.3em);
        position: relative;
        .left-wrapper {
            width: 22em;
            overflow-y: auto;
            position: relative;
            height: 100%;
            .alerts-section {
                @include flexbox(flex-start, flex-start, column, nowrap);
                padding: 0 1.2em;
                margin: 1em 1em 0.8em 0;
                .workshift {
                    @include flexbox(center, center, column, nowrap);
                    background: linear-gradient(to right top, #00c6ff 0%, #0072ff 100%);
                    text-transform: uppercase;
                    padding: .3em .5em;
                    font-size: .8em;
                    font-weight: 500;
                    letter-spacing: .06em;
                    border-radius: .2em;
                    margin: .7em 0 1.5em;
                    &__header {
                        @include flexbox(space-between, center, row, nowrap);
                        span {
                            margin-right: .45em; } }
                    &__body {
                        @include flexbox(center, center, column, nowrap);
                        font-size: 4em;
                        font-weight: bold; } } } }

        .map-wrapper {
            position: absolute;
            right: 0;
            height: 100%;
            width: calc(100% - 8em);
            border-radius: .4em;
            overflow: hidden;
            @include flexbox(center, center, column, nowrap);
            .map-container {
                @include position(absolute, 0, 0, 0, 0);
                z-index: 0; }
            .map-info-panel {
                visibility: hidden;
                z-index: 2;
                @include position(absolute, 0, 0, 0, 0);
                padding: 1em;
                @include flexbox(flex-start, flex-end, row, nowrap);
                .legend:hover {
                    width: auto !important;
                    .legend-element {
                        span {
                            display: block; } } }
                .legend {
                    @include position(absolute, 5em, unset, unset, .54em);
                    padding: .4em .5em;
                    border-radius: .2em;
                    visibility: visible;
                    background-color: $main-bg-color;
                    color: $main-text-color;
                    width: .8em;
                    @include flexbox(center, flex-start, column, nowrap);
                    .legend-element {
                        @include flexbox(flex-start, center, row, nowrap);
                        margin: .1em 0;
                        .color-square {
                            @include square(.8em);
                            margin-right: .4em; }
                        span {
                            display: none;
                            font-weight: 500;
                            font-size: .8em;
                            line-height: .8em; } } }
                .map-filters-controller {
                    visibility: visible;
                    @include position(absolute, 1em, 1em, unset, unset);
                    .filters-wrapper {
                        width: 9em;
                        @include flexbox(flex-start, flex-start, column, nowrap);
                        .filter-trigger {
                            z-index: 1;
                            cursor: pointer;
                            @include flexbox(space-between, center, row, nowrap);
                            background-color: $main-text-color;
                            border-radius: .4em;
                            border: 0.07em solid $main-bg-color;
                            padding: .5em .7em;
                            position: relative;
                            width: calc(100% - 1.4em);
                            color: $main-bg-color;
                            font-weight: 500;
                            > div {
                                margin-right: .2em;
                                background: lighten($main-bg-color, 30%); } }
                        .filters-list {
                            z-index: 2;
                            user-select: none;
                            display: none;
                            position: relative;
                            top: -0.5em;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            flex-wrap: nowrap;
                            background-color: $main-text-color;
                            border: 0.07em solid $main-bg-color;
                            border-top: none;
                            border-radius: 0 0 .4em .4em;
                            padding: .3em .7em 0 .7em;
                            width: calc(100% - 1.4em);
                            color: $main-bg-color;
                            .filter {
                                @include flexbox(space-between, center, row, nowrap);
                                position: relative;
                                width: 97%;
                                span {
                                    font-weight: bold;
                                    font-size: .9em; } } } } }

                .rivers-alerts {
                    visibility: visible;
                    @include flexbox(flex-start, flex-end, row, nowrap); } } } }
    .meteo-widget {
        position: relative;
        width: 100%; } }
