$main-bg-color: #000000;
$secondary-bg-color: #3b3b3b;
$main-text-color: #ffffff;
$main-accent-color: #448AFF;
$green: #39ff14;
$yellow: #FFEA00;
$orange: #FF8F00;
$red: #FF3D00;

$accent-value: '448aff';

$small-phone-size: 350px;
$phone-size: 600px;
$tablet-size: 992px;

$nav-icon-size: 22px;
$nav-icon-button-size: $nav-icon-size - 2;
$nav-user-image-size: 38px;
$mini-icon-size: 16px;
$map-sprite-size: 50px;
$alert-icon-size: $map-sprite-size;
$weather-icon-size: $map-sprite-size;
$large-sprite-size: 128px;

@mixin breakpoint($class) {
  @if $class == 'phone-landscape' {
    @media (max-width: $phone-size) and (orientation: landscape) {
      @content; } }

  @if $class == '<small-phone' {
    @media (max-width: $small-phone-size) {
      @content; } }

  @if $class == '>small-phone' {
    @media (min-width: $small-phone-size) {
      @content; } }

  @if $class == '<phone' {
    @media (max-width: $phone-size) {
      @content; } }

  @if $class == '>phone' {
    @media (min-width: $phone-size) {
      @content; } }

  @if $class == '<tablet' {
    @media (max-width: $tablet-size) {
      @content; } }

  @if $class == '>tablet' {
    @media (min-width: $tablet-size) {
      @content; } } }

@mixin square($size) {
  height: $size;
  width: $size; }

@mixin position($position, $top, $right, $bottom, $left) {
  position: $position;
  top: $top;
  bottom: $bottom;
  left: $left;
  right: $right; }

@mixin flexbox($justify, $align, $direction, $wrap) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
  flex-direction: $direction;
  flex-wrap: $wrap; }

@mixin sprite($size, $posY) {
  @include square($size);
  background-image: url(../media/images/system_icons.svg);
  background-repeat: no-repeat;
  background-size: $size ($size * 31);
  background-position: center ($posY * -1); }

@mixin icon($size, $posY) {
  @include square($size);
  mask-image: url(../media/images/system_icons.svg);
  mask-repeat: no-repeat;
  mask-size: $size ($size * 31);
  mask-position: center ($posY * -1);
  background: rgba($main-text-color, .9); }
