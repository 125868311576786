@import "mixins";

.settings-card:not(:first-child) {
  margin-top: 2em; }

.settings-card:not(:last-child) {
  margin-bottom: 2em; }

.card-header, .card-body {
  position: relative;
  width: 100%; }

.card-header {
  @include flexbox(space-between !important, center, row, nowrap);

  .card-title {
    margin: 0;
    font-size: .95em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: darken($main-text-color, 20%); } }

.card-body {
  @include flexbox(center, center, column, nowrap);

  .photo-wrapper:hover {
    .photo-action {
      opacity: 1; } }

  .photo-wrapper {
    @include flexbox(center, center, column, nowrap);
    @include square($large-sprite-size - 1);
    overflow: hidden;
    border: .18em solid $main-accent-color;
    border-radius: 50%;
    margin-bottom: 2.5em;
    position: relative;

    img {
      @include flexbox(center, center, column, nowrap);
      position: relative;
      height: 100%;
      width: auto; }

    .photo-action {
      opacity: 0;
      @include flexbox(center, center, column, nowrap);
      text-align: center;
      @include position(absolute, 0, 0, 0, 0);
      font-size: .8em;
      font-weight: bold;
      letter-spacing: .03em;
      background-color: rgba(0, 0, 0, .45);
      transition: opacity .3s ease;
      label {
        cursor: pointer;
        @include flexbox(center, center, column, nowrap);
        position: relative;
        @include square(100%); } } }

  .info-wrapper {
    position: relative;
    width: 600px;
    @include flexbox(space-evenly, center, row, wrap);

    .info-list {
      color: darken($main-text-color, 10%);
      font-size: .85em;
      letter-spacing: .03em;
      @include flexbox(center, flex-start, column, nowrap);

      span {
        line-height: 1.8em; } } } }

.actions-list {
  @include flexbox(center, center, column, nowrap);

  button:not(:first-child) {
    margin-top: .5em; }

  button:not(:last-child) {
    margin-bottom: .5em; } }

.telegram-section {
  margin-top: 3em;

  .telegram-card {
    width: fit-content;
    margin: 0 auto;

    .telegram-sprite {
      padding-bottom: 1px; }

    .actions-list {
      margin-top: 1em;

      .telegram-hint {
        margin: 0 0 1.4em 0;
        font-size: 0.9em;
        letter-spacing: 0.04em;
        font-weight: bold;
        color: darken($main-text-color, 25%); }

      .qr-container {
        @include square(9em);
        border: .07em solid darken($main-text-color, 20%);
        border-radius: .4em;
        margin-bottom: .8em;
        @include flexbox(center, center, column, nowrap);
        background-color: $main-text-color;

        canvas {
          position: relative;
          height: 90%;
          width: 90%; } }

      > a {
        font-weight: bold;
        font-size: .85em; } } } }

.notify-table {
  margin-bottom: 0 !important;
  border: 0 !important; }

.name-element {
  color: $main-text-color !important;
  user-select: none; }

.check-element {
  @include flexbox(center, center, row, nowrap); }
