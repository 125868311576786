@import "../mixins";

.river:not(:first-child) {
  margin-left: .5em; }
.river:not(:last-child) {
  margin-right: .5em; }
.river {
  @include flexbox(center, center, row !important, nowrap);
  width: 19em;
  position: relative;
  padding-left: 0 !important;
  .left-section {
    @include flexbox(center, center, column, nowrap);
    position: relative;
    width: 5.7em;
    .alert-color {
      @include square(1.4em);
      border-radius: 50%;
      background-color: inherit; }
    .threshold-zero {
      background-color: $green; }
    .threshold-one {
      background-color: $yellow; }
    .threshold-two {
      background-color: $orange; }
    .threshold-three {
      background-color: $red; } }
  .right-section {
    @include flexbox(center, flex-start, column, nowrap); }
  .value-section {
    @include flexbox(center, center, row, nowrap);
    color: darken($main-text-color, 20%);
    .level {
      @include flexbox(center, center, row, nowrap);
      margin: .1em .2em .1em 0;
      line-height: .5em;
      font-size: 2.4em;
      color: $main-text-color;
      &:after {
        content: 'm';
        position: relative;
        font-size: .4em;
        margin: 1.15em 0 0 .3em; } } } }
