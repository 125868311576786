@import "mixins";

.team-card {
    margin-top: 2em; }

.team-container {
    @include flexbox(center, flex-start, row, wrap);
    padding: 2em 1em 1em;
    position: relative;
    width: 60%;

    .team-member:hover {
        .member-image {
            .remove-button {
                opacity: 1; } } }
    .team-member {
        width: 9em;
        margin: 1em;
        @include flexbox(center, center, column, nowrap);
        text-align: center;
        .member-image {
            @include flexbox(center, center, column, nowrap);
            @include square(6em);
            border: .14em solid $main-accent-color;
            border-radius: 50%;
            position: relative;
            .remove-button {
                @include flexbox(center, center, column, nowrap);
                cursor: pointer;
                opacity: 0;
                @include position(absolute, 0, 0, unset, unset);
                @include square(1.6em);
                border-radius: 50%;
                background-color: darken($red, 5%);
                transition: opacity .25s ease; } }
        .member-name {
            margin-top: .8em;
            font-size: .87em;
            font-weight: 500;
            letter-spacing: .035em; } }
    .add-member {
        .add-image {
            cursor: pointer;
            @include flexbox(center, center, column, nowrap);
            @include square(6em);
            background-color: rgba($main-accent-color, .8);
            border-radius: 50%;
            position: relative;
            transition: background-color .25s ease;
            &:hover {
                background-color: darken(rgba($main-accent-color, .8), 10%); } } } }

.small-map-container {
    z-index: 0;
    margin-top: 2em;
    position: relative;
    width: 100%;
    height: 30em;
    border-radius: 0.2em; }
