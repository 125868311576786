@import "../mixins";

.visible {
  transform: scale(1) !important; }

.modal-panel {
  transform: scale(0);
  z-index: 8;
  @include position(fixed, 0, 0, 0, 0);
  min-height: 100%;
  @include flexbox(center, center, column, nowrap);

  .dark-cover {
    @include position(absolute, 0, 0, 0, 0);
    background-color: rgba($main-bg-color, 0.48); }

  .modal {
    position: relative;
    min-width: 600px;
    justify-content: flex-start;

    .card-header {
      .remove-icon {
        cursor: pointer; } }

    .card-body {
      @include flexbox(space-between, center, row, wrap); }

    .body-column {
      @include flexbox(center, center, column, wrap);

      .delete-hint {
        font-size: 0.85em; } }

    .card-footer {
      @include position(absolute, unset, 1em, 1em, unset);
      @include flexbox(flex-end, center, row, nowrap);

      button:not(:first-child) {
        margin-left: .7em; }

      button:not(:last-child) {
        margin-right: .7em; } } } }

.card-loader-container {
  min-height: 4em;
  position: relative;
  width: 100%;
  @include flexbox(center, center, column, nowrap); }

.image-edit-panel {
  margin: 3em;
  width: 12em;
  @include flexbox(center, center, column, nowrap); }

#choose-user-image {
  display: none; }

.identity-wrapper {
  @include flexbox(center, center, column, nowrap); }

.input-row {
  position: relative;
  width: 100%;
  @include flexbox(flex-start, center, row, nowrap);
  margin: 0.5em 0;

  .input-container {
    @include flexbox(center, center, row, nowrap);
    position: relative;

    .group {
      position: relative;
      margin: 1em 0;
      width: 16em;

      &:focus ~ .bar:before, &:focus ~ .error-bar:before {
        width: 100%; }

      input {
        width: 100%;

        &:focus ~ .bar:before, &:focus ~ .error-bar:before {
          width: 100%; } }

      input {
        background: none;
        color: inherit;
        letter-spacing: .06em;
        font-size: .9em;
        padding: .5em 0;
        display: block;
        border: none;
        border-radius: 0;
        border-bottom: .05em solid darken($main-text-color, 70%);

        &:focus {
          outline: none; }

        &:focus ~ label, &:valid ~ label {
          top: -1em;
          font-size: .7em;
          color: $main-accent-color; }

        &:focus ~ .error-label, &:valid ~ .error-label {
          color: $red !important; } }

      label {
        color: darken($main-text-color, 70%);
        font-size: .8em;
        letter-spacing: .06em;
        position: absolute;
        pointer-events: none;
        left: 0;
        top: .4em;
        transition: .3s ease all; }

      .bar {
        &:before {
          background: $main-accent-color; } }

      .error-bar {
        &:before {
          width: 100% !important;
          background: $red; } }

      .bar, .error-bar {
        position: relative;
        display: block;
        width: 100%;

        &:before {
          @include position(absolute, unset, unset, 0, 0%);
          content: '';
          height: .1em;
          width: 0;
          bottom: 0;
          position: absolute;

          transition: .3s ease all;
          left: 0%; } } } } }

.code {
  background-color: #4d4d4d;
  color: #b5b5b5;
  padding: 5px;
  border-radius: 4px; }
