@import url('https://fonts.googleapis.com/css?family=Montserrat:200,400,500');

html {
    height: 100%;
}
body {
    font-size: 16px;
    position: relative;
    min-height: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000000;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
}
