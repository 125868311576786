@import "../mixins";

.login-panel,
.input-section {
    @include flexbox(center, center, column, nowrap); }

.login-panel {
    @include position(fixed, 0, 0, 0, 0);
    @include flexbox(center, center, column, nowrap);
    .login {
        position: relative;
        background-color: darken($main-text-color, 85%);
        border-color: opacify(darken($main-accent-color, 20%), .5);
        .login-wrapper {
            .input-section {
                .input-container {
                    @include flexbox(center, center, row, nowrap);
                    position: relative;
                    .group {
                        position: relative;
                        margin: 1em 0;
                        width: 16em;
                        &:focus ~ .bar:before {
                            width: 100%; }
                        input {
                            width: 100%;
                            &:focus ~ .bar:before {
                                width: 100%; } }

                        input {
                            background: none;
                            color: inherit;
                            letter-spacing: .06em;
                            font-size: .9em;
                            padding: .5em 0;
                            display: block;
                            border: none;
                            border-radius: 0;
                            border-bottom: .05em solid darken($main-text-color, 70%);
                            &:focus {
                                outline: none; }
                            &:focus ~ label, &:valid ~ label {
                                top: -1em;
                                font-size: .7em;
                                color: $main-accent-color; } }
                        label {
                            color: darken($main-text-color, 70%);
                            font-size: .8em;
                            letter-spacing: .06em;
                            position: absolute;
                            pointer-events: none;
                            left: 0;
                            top: .4em;
                            transition: .3s ease all; }
                        .bar {
                            position: relative;
                            display: block;
                            width: 100%;
                            &:before {
                                @include position(absolute, unset, unset, 0, 0%);
                                content: '';
                                height: .1em;
                                width: 0;
                                bottom: 0;
                                position: absolute;
                                background: $main-accent-color;
                                transition: .3s ease all;
                                left: 0%; } } }
                    .see-button {
                        cursor: pointer;
                        @include position(absolute, unset, 0, unset, unset);
                        background-color: darken($main-text-color, 85%);
                        padding: 0 .3em;
                        opacity: .2; } } } }
        .error {
            @include position(absolute, unset, unset, -3em, unset);
            font-size: .75em;
            letter-spacing: .06em;
            font-weight: 500;
            color: darken($red, 10%); }
        button {
            cursor: pointer;
            outline: none;
            font-family: 'Montserrat', sans-serif;
            appearance: none;
            border: none;
            background: $main-bg-color;
            border-radius: .2em;
            text-transform: capitalize;
            color: $main-text-color;
            font-size: 1em;
            font-weight: 400;
            letter-spacing: .06em;
            width: 100%;
            padding: .5em 0;
            margin-top: .4em;
            &[disabled] {
                display: none; } } }
    .name-logo {
        margin-bottom: 2em;
        height: 1.3em;
        width: 5.3em;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(../../media/images/title.svg); }
    .credits {
        @include position(absolute, unset, unset, 1em, unset);
        color: $main-text-color;
        font-size: .8em;
        font-weight: 500;
        letter-spacing: .04em; } }
