.interventions-map-container {
    height: 40em;
    border-radius: 0.4em;
    overflow: hidden;
    .map {
        position: relative;
        height: 100%;
        width: 100%; } }

.history-section {
    margin-top: 3em; }
